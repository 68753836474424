<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <div v-loading="loading">
      <Crumbs>
        <el-breadcrumb-item slot="after">
          {{ options.id ? '编辑' : '新增' }}商务信息
        </el-breadcrumb-item>
      </Crumbs>
      <div class="fullScreenMain">
        <el-tabs v-model="activeName" class="fullScreenMainHeader">
          <el-tab-pane name="0" label="基本信息"></el-tab-pane>
          <el-tab-pane name="1" label="附件"></el-tab-pane>
          <el-tab-pane name="2" label="商务团队分工" v-if="form.id"></el-tab-pane>
          <el-tab-pane name="3" label="商务追踪" v-if="form.id"></el-tab-pane>
          <el-tab-pane name="4" label="商务绩效" v-if="form.id"></el-tab-pane>
        </el-tabs>

        <div class="fullScreenMainCon" style="width: 100%">
          <el-form
            ref="form"
            class="tianbaoForm"
            label-width="0em"
            v-show="activeName == '0'"
            :model="form"
            :rules="rules"
          >
            <Manage
              width="600px"
              ref="Manage"
              :BusinessId="options.id"
              :options="form"
              :isShowSuggest="true"
              :isBusinessLog="true"
              :disbursementDisabled="true"
              @getBusinessFile="getBusinessFile"
              @showEditDialog="showEditDialog = true"
            ></Manage>

            <div v-show="opinionRecordList.length > 0">
              <h3 v-if="form.id">意见区：</h3>
              <div class="text-center">
                <template>
                  <div class="text-center-top" v-for="i in opinionRecordList" :key="i.id">
                    <span class="text-center-top-left">
                      <el-button
                        class="text-btn"
                        type="success"
                        disabled
                        v-if="i.isCheckPass === '1'"
                        >通过</el-button
                      >
                      <el-button type="info" class="text-btn" disabled v-else>不通过</el-button>
                      <span class="opinion">审批意见：{{ i.opinion }}</span>
                    </span>
                    <span class="text-center-top-right">
                      <span class="name">处理人：{{ i.auditUserName }}</span>
                      <span class="day">审批时间：{{ i.auditDate | timeFormat }}</span>
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </el-form>
          <Annex
            :manageId="form.id"
            ref="Annex"
            @changed="changeField"
            :file_Data="file_Data"
            v-show="activeName == 1"
          ></Annex>
          <Assign width="600px" :ManageId="form.id" v-show="activeName == 2"></Assign>
          <Business width="600px" :BusinessId="form.id" v-show="activeName == 3"></Business>
          <DistriBution
            width="600px"
            :options="{ businessManageId: form.id }"
            :Disabled="true"
            v-if="activeName == 4"
          ></DistriBution>
        </div>

        <div class="fullScreenOperation">
          <el-button type="success" :loading="loading" @click="handleSave(1)"> 确认修改 </el-button>
          <el-button
            type="danger"
            v-if="permission(['SWXX_DELETE'])"
            :loading="loading"
            @click="delFn"
          >
            删除商务信息
          </el-button>
          <el-button
            v-if="
              businessInformation &&
              businessInformation.isFinality &&
              businessInformation.isFinality &&
              businessInformation.status < 5
            "
            class="finality"
            :loading="loading"
            @click="dialogFormVisible = true"
          >
            商务终结
          </el-button>
          <el-button type="info" @click="re">返回</el-button>
        </div>
      </div>
    </div>
    <EditDialog :isShow.sync="dialogFormVisible">
      <div slot="title" class="title">商务终结</div>
      <div slot="content" class="content">
        <el-form :model="examineForm" :rules="rules_form" ref="ruleForm">
          <el-form-item prop="opinion">
            <el-input type="textarea" placeholder="请输入原因" v-model="examineForm.opinion"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="success" :loading="loading" @click="finalityFn">确定终结</el-button>
        <el-button type="info" @click="dialogFormVisible = false">取消</el-button>
      </div>
    </EditDialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { phone2 } from '@/util/regular'
import { getInputValue, delcommafy, numberToCurrencyNo } from '@/util/jsencrypt'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Annex: () => import('./Annex.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    Assign: () => import('@/views/publicView/Assign.vue'),
    Business: () => import('@/views/publicView/Business.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Manage: () => import('@/views/publicView/Manage.vue'),
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    edit_flag: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      businessInformation: state => state.business.businessInformation,
    }),
  },
  data() {
    return {
      showEditDialog: false,
      loading: false,
      submitLoading: false,
      form: { ourContractAmount: 0, vatRate: 0, isRequire: 1 },
      activeName: '0',
      rules_form: {
        opinion: [{ required: true, message: '请输入商务终结原因', trigger: 'blur' }],
      },
      rules: {
        businessName: [
          {
            required: true,
            message: '请输入商务名称',
            trigger: ['blur', 'change'],
          },
        ],
        ownerUnit: [
          {
            required: true,
            message: '请输入业主单位',
            trigger: ['blur', 'change'],
          },
          // {
          //   pattern: phone,
          //   message: "请输入正确的联系电话",
          //   trigger: ["change", "blur"],
          // },
        ],
        contactsPhone: [
          {
            pattern: phone2,
            message: '请输入正确的联系电话',
            trigger: ['change', 'blur'],
          },
        ],
        // businessType: [
        //   {
        //     required: true,
        //     message: '请选择商务类型',
        //     trigger: ['blur', 'change'],
        //   },
        // ],
      },
      deptList: [], // 部门列表
      postList: [], //岗位列表
      type: 'portrait',
      file_Data: [],
      y_file_Data: [],
      list_s: '0', //列表进入状态
      val: {},
      opinionRecordList: [],
      user_show: false,
      UserData: [],
      linshi_initiator: '',
      zzsList: [],
      user_Data: [],
      suggestion_show: false,
      suggestionPrincipalName: '',
      principal_show: false,
      principalName: '',
      businessFileList: [],
      examineForm: {
        statusDescription: '',
      },
      dialogFormVisible: false,
    }
  },
  inject: ['re'],
  created() {},
  watch: {
    // isShow: {
    //   immediate: true, //初始化立即执行
    //   handler: function (newVal) {
    //     // this.showEditDialog = newVal
    //     if (newVal) {
    //       // this.opinionRecordList = []
    //       // this.getUser()
    //     }
    //   },
    // },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.val = newVal
        // this.form.id = newVal.id;
        this.list_s = this.val.list_s
        if (newVal.id) {
          this.form.list_s = 1
        } else {
          this.form = {
            ...newVal,
            initiatorId: this.userInfo.staffId,
            provideId: this.userInfo.staffId,
            ourContractAmount: 0,
            vatRate: 0,
            tenderFee: 0,
            contentCooperationMoney: 0,
          }
          this.linshi_initiator = this.userInfo.userName

          this.file_Data = []
        }
      },
    },
    'form.contentCooperationType': {
      handler: function (val) {
        if (val == 0) {
          this.form.contentCooperationMoney = null
          this.form.contentCooperationRatio = null
        } else if (val == 1) {
          this.form.contentCooperationRatio = null
        } else if (val == 2) {
          this.form.contentCooperationMoney = null
        }
      },
    },
  },
  methods: {
    finalityFn() {
      this.$confirm('确定终结该商务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true

          this.$api.businessManage
            .finality({
              id: this.options.id,
              statusDescription: this.examineForm.opinion,
            })
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('终结成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log(err)
            })
        })
        .catch(() => {})
    },
    getBusinessFile(arr) {
      this.file_Data = [...arr]
      this.y_file_Data = [...arr]
    },
    changeField(arr) {
      this.businessFileList = arr
    },
    delFn() {
      this.$confirm('此操作将删除该商务信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.$api.businessManage
            .deleteBusiness(this.options.id)
            .then(res => {
              this.showEditDialog = false
              this.re()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSave(status) {
      if (
        this.file_Data.some(r => {
          return r.loading
        })
      ) {
        this.$message.warning('文件上传中，请稍后再试！')
        return ''
      }
      this.$refs.Manage.businessEditCheck()
      let isEditCheck = this.$refs.Manage?.isEditCheck
      if (isEditCheck) {
        let Form = { ...this.$refs.Manage.form }

        this.form = Form
        this.form.taskDate = new Date().getTime()
        this.form.ourContractAmount = Number(delcommafy(this.form.ourContractAmount))
        this.form.predictMoney = Number(delcommafy(this.form.predictMoney))
        this.form.contentCooperationMoney = Number(delcommafy(this.form.contentCooperationMoney))
        // this.form.collaborationCosts = Number.parseInt(delcommafy(this.form.collaborationCosts))
        this.form.tenderFee = Number(delcommafy(this.form.tenderFee))
        this.form.taxesFee = Number(delcommafy(this.form.taxesFee))
        this.form.actualStampTax = Number(delcommafy(this.form.actualStampTax))
        this.form.actualAdditionalTax = Number(delcommafy(this.form.actualAdditionalTax))
        this.form.contentCooperationRatio = Number(delcommafy(this.form.contentCooperationRatio))
        // 筛选出修改过的字段
        const oldBusinessForm = this.$refs.Manage.oldBusinessForm
        let editArr = []
        Object.keys(oldBusinessForm).forEach(oldKey => {
          let old = oldBusinessForm[oldKey]
          if (typeof oldBusinessForm[oldKey] === 'string') {
            old = oldBusinessForm[oldKey].trim()
          }
          Object.keys(this.form).forEach(key => {
            let e = this.form[key]
            if (typeof this.form[key] === 'string') {
              e = this.form[key].trim()
            }
            if (
              oldKey === key &&
              old !== e &&
              !['principalUserName', 'suggestionPrincipalUserName'].includes(oldKey) &&
              !['principalUserName', 'suggestionPrincipalUserName'].includes(e)
            ) {
              editArr.push(key)
            }
          })
        })
        this.form.status = status

        //增加标识，是否全部商务中修改
        if (this.edit_flag) {
          this.form.editFlag = 1
        }
        let text = ''
        if (this.form.editApproveJumpOver == 'ISJUMPOVER') {
          text = '其他信息是否已修改完善'
        } else if (this.form.editApproveJumpOver == 'NOTJUMPOVER') {
          text = '修改商务信息后将重新提交审核'
        }

        this.$confirm(`${text}, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            var cond = {
              businessFileList: this.businessFileList,
              businessFileDeleteList: [],
              businessManage: { ...this.form, changeFieldList: editArr }, //记录变更字段
              bidId: this.form.bidId || '',
              bidIdList: this.form.bidIdList || '',
            }
            if (this.y_file_Data != []) {
              //判断被删除得文件
              this.y_file_Data.some(res => {
                if (
                  !cond.businessFileList.some(r => {
                    return r.id == res.id
                  })
                ) {
                  cond.businessFileDeleteList.push(res.id)
                }
              })
            }

            this.loading = true
            this.$api.businessManage
              .addBusinessManage(cond)
              .then(res => {
                this.loading = false
                this.showEditDialog = false
                this.$message.success('操作成功！')
                this.re()
              })
              .catch(err => {
                this.loading = false
                console.log(err)
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消修改',
            })
          })
      } else {
        this.activeName = '0'
        this.$message.error('请填写必填信息')
      }
    },
  },
}
</script>

<style scoped lang="scss">
// @import "@/styles/edit.scss";

// @import "@/styles/config.scss";

.el-form-item {
  margin-bottom: 0px;
  padding: 0;
}

.text-center {
  width: calc(100vw - 320px);
  height: 300px;
  //   border: 1px solid #000;
  overflow: auto;
  padding: 16px;
  .text-center-top {
    margin-bottom: 10px;
    color: #000;
    background-color: #f3f3f5;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text-center-top-left {
      // width: 50%;
      flex: 1;
      display: flex;
      align-items: center;
      .text-btn {
        height: 48px;
        width: 90px;
        margin-right: 20px;
      }
      .opinion {
        display: inline-block;
        margin-left: 8px;
        width: 100%;
        word-break: break-word;
        padding: 5px 0;
        font-family: PingFang SC;
        font-weight: 600;
        // font-size: 18px;
      }
    }
    .text-center-top-right {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      .day {
        margin-left: 10%;
        padding-right: 8px;
      }
    }
  }
}

.danwei {
  position: absolute;
  right: 2%;
}

.form_box {
  padding-top: 20px;
}

.margin-top {
  margin-top: 10px;
}
/deep/.el-loading-spinner {
  margin-top: 21%;
}
/deep/.finality {
  background-color: #e9dcdc;
}
</style>
